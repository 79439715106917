import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider, split } from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { getMainDefinition } from '@apollo/client/utilities';
import { isValidToken } from 'src/auth/context/jwt/utils';
import { useAuthContext } from 'src/auth/hooks';
import { DGRAPH_API } from 'src/config-global';
import { setAxiosToken } from 'src/utils/axios';

export default function ApolloProviderCustom({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { getIdTokenClaims } = useAuth0();
  const { logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getIdTokenClaims()
      .then((claims) => {
        if (claims && isValidToken(claims.__raw)) {
          return setAxiosToken(claims.__raw);
        }
        return setAxiosToken('');
      })
      .catch(() => setAxiosToken(''));
  }, [getIdTokenClaims]);

  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      // get the authentication token from auth0 if it exists
      const token = await getIdTokenClaims();
      if (!isValidToken(token?.__raw || '')) {
        enqueueSnackbar({ message: 'Session expired!', variant: 'error' });
        setTimeout(async () => {
          await logout();
        }, 2000);
        // return the headers to the context so httpLink can read them
        setAxiosToken();
        return {
          headers: {
            ...headers,
          },
        };
      }
      setAxiosToken(token?.__raw || '');
      return {
        headers: {
          ...headers,
          'X-Auth-Token': token?.__raw || '',
        },
      };
    });

    const httpLink = createHttpLink({
      uri: DGRAPH_API.httpsUrl,
    });

    const wsLink = new WebSocketLink(
      new SubscriptionClient(DGRAPH_API.websocketUrl, {
        connectionParams: async () => {
          const token = await getIdTokenClaims();
          return { 'X-Auth-Token': token?.__raw || '' };
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      authLink.concat(httpLink)
    );

    return new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
    });
  }, [enqueueSnackbar, getIdTokenClaims, logout]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
