import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/admin/home'));
// SUBSCRIPTIONS
const SubscriptionsListPage = lazy(() => import('src/pages/admin/contracts/subscriptions/list'));
const SubscriptionsCreatePage = lazy(() => import('src/pages/admin/contracts/subscriptions/new'));
const SubscriptionsEditPage = lazy(() => import('src/pages/admin/contracts/subscriptions/edit'));
const SubscriptionsDetailsPage = lazy(
  () => import('src/pages/admin/contracts/subscriptions/details')
);
// LOCATIONS
const LocationsListPage = lazy(() => import('src/pages/admin/locations/list'));
const LocationsCreatePage = lazy(() => import('src/pages/admin/locations/new'));
const LocationsEditPage = lazy(() => import('src/pages/admin/locations/edit'));
const LocationsDetailsPage = lazy(() => import('src/pages/admin/locations/details'));
// AGREEMENTS
const AgreementsListPage = lazy(() => import('src/pages/admin/contracts/agreements/list'));
const AgreementsDetailsPage = lazy(() => import('src/pages/admin/contracts/agreements/details'));
const AgreementsCreatePage = lazy(() => import('src/pages/admin/contracts/agreements/new'));
const AgreementsEditPage = lazy(() => import('src/pages/admin/contracts/agreements/edit'));
// Companies
const CompaniesDetailsPage = lazy(() => import('src/pages/admin/companies/details'));

// Company - Clients
const ClientsListPage = lazy(() => import('src/pages/admin/companies/clients/list'));
// Company - Owners
const OwnersListPage = lazy(() => import('src/pages/admin/companies/owners/list'));
// Company - Vendors
const VendorsListPage = lazy(() => import('src/pages/admin/companies/vendors/list'));
// ----------------------------------------------------------------------

export const adminRoutes = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'contracts',
        children: [
          {
            element: <Navigate to="subscriptions" replace />,
            index: true,
          },
          {
            path: 'subscriptions',
            children: [
              { element: <SubscriptionsListPage />, index: true },
              { path: 'list', element: <SubscriptionsListPage /> },
              { path: 'new', element: <SubscriptionsCreatePage /> },
              { path: ':id', element: <SubscriptionsDetailsPage /> },
              { path: ':id/edit', element: <SubscriptionsEditPage /> },
            ],
          },
          {
            path: 'agreements',
            children: [
              { element: <AgreementsListPage />, index: true },
              { path: 'list', element: <AgreementsListPage /> },
              { path: 'new', element: <AgreementsCreatePage /> },
              { path: ':id', element: <AgreementsDetailsPage /> },
              { path: ':id/edit', element: <AgreementsEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'locations',
        children: [
          { element: <LocationsListPage />, index: true },
          { path: 'list', element: <LocationsListPage /> },
          { path: 'new', element: <LocationsCreatePage /> },
          { path: ':id', element: <LocationsDetailsPage /> },
          { path: ':sku/edit', element: <LocationsEditPage /> },
        ],
      },
      {
        path: 'companies',
        children: [
          { path: ':id', element: <CompaniesDetailsPage /> },
          {
            element: <Navigate to="owners" replace />,
            index: true,
          },
          {
            path: 'clients',
            children: [
              { element: <ClientsListPage />, index: true },
              { path: 'list', element: <ClientsListPage /> },
            ],
          },
          {
            path: 'owners',
            children: [
              { element: <OwnersListPage />, index: true },
              { path: 'list', element: <OwnersListPage /> },
            ],
          },
          {
            path: 'vendors',
            children: [
              { element: <VendorsListPage />, index: true },
              { path: 'list', element: <VendorsListPage /> },
            ],
          },
        ],
      },
    ],
  },
];
