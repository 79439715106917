import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const setAxiosToken = (value?: string | null) => {
  axiosInstance.defaults.headers.common['X-Auth-Token'] = value || '';
};

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  stripe: {
    account: (companyId: string) => `api/stripe/account/${companyId}`,
    customer: (companyId: string) => `api/stripe/customer/${companyId}`,
  },
  facturapi: {
    invoicePdf: (url: string) => `api/facturapi/invoice-pdf?url=${url}`,
  },
  contract: {
    uploadPdf: 'api/admin/upload_contract_pdf',
    charge: 'api/charge_contract',
    payout: 'api/payout_contract',
  },
  company: {
    new: 'api/new_company',
    update: 'api/update_company',
    uploadDocument: 'api/admin/upload_company_document',
  },
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  invoice: {
    cancel: '/api/admin/cancel_invoice',
  },
};
