// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const DEV_MODE = process.env.REACT_APP_ENV !== 'production';
export const HOST_URL = process.env.REACT_APP_HOST_API;
export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  logoutUrl: process.env.REACT_APP_AUTH0_LOGOUT_URL,
};

export const DGRAPH_API = {
  httpsUrl: `https://${process.env.REACT_APP_DGRAPH_URL}`,
  websocketUrl: `wss://${process.env.REACT_APP_DGRAPH_URL}`,
  aroundPayerId: `${process.env.REACT_APP_AROUND_PAYER_ID}`,
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.admin.root; // as '/dashboard'
