// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  ADMIN: '/admin',
};

// ----------------------------------------------------------------------
// if getting path.endsWith error
// check if src/layouts/dashboard/config-navigation.tsx needs update
export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  // ADMIN
  admin: {
    root: `${ROOTS.ADMIN}`,
    contracts: {
      root: `${ROOTS.ADMIN}/contracts`,
      subscriptions: {
        root: `${ROOTS.ADMIN}/contracts/subscriptions`,
        new: `${ROOTS.ADMIN}/contracts/subscriptions/new`,
        details: (id: string) => `${ROOTS.ADMIN}/contracts/subscriptions/${id}`,
        edit: (id: string) => `${ROOTS.ADMIN}/contracts/subscriptions/${id}/edit`,
      },
      agreements: {
        root: `${ROOTS.ADMIN}/contracts/agreements`,
        new: `${ROOTS.ADMIN}/contracts/agreements/new`,
        details: (id: string) => `${ROOTS.ADMIN}/contracts/agreements/${id}`,
        edit: (id: string) => `${ROOTS.ADMIN}/contracts/agreements/${id}/edit`,
      },
    },
    locations: {
      root: `${ROOTS.ADMIN}/locations`,
      new: `${ROOTS.ADMIN}/locations/new`,
      details: (sku: string) => `${ROOTS.ADMIN}/locations/${sku}`,
      edit: (sku: string) => `${ROOTS.ADMIN}/locations/${sku}/edit`,
    },
    companies: {
      root: `${ROOTS.ADMIN}/companies`,
      details: (id: string) => `${ROOTS.ADMIN}/companies/${id}`,
      clients: {
        root: `${ROOTS.ADMIN}/companies/clients`,
      },
      owners: {
        root: `${ROOTS.ADMIN}/companies/owners`,
      },
      vendors: {
        root: `${ROOTS.ADMIN}/companies/vendors`,
      },
    },
  },
};
